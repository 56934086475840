import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Form,
  Label,
  Button,
  Dropdown,
  Header,
  Popup,
  Icon,
} from 'semantic-ui-react'
import { translate } from 'react-i18next'
import { Field } from 'react-final-form'
import { get } from 'lodash'

import {
  minLength,
  maxLength,
  pattern,
  matchesField,
  required,
  numRange,
  composeValidators,
} from '../../../helpers/form-validators'
import {
  trimInputOnBlur,
  lowerCaseFormatter,
  dropdownOnChange,
  checkboxOnChange,
} from '../../../helpers/form'
import {
  JOB_TITLE_OPTIONS,
} from '../../../helpers/user'
// import RadioGroup from '../../common/radio-group'
import BaseForm from '../base-form/base-form'
import ConditionalField from '../../common/conditional-field'
import { WELCOME_COURSE_IDS } from '../../../helpers/module-constants'

const EMAIL_PATTERN = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/

const validateUserName = composeValidators(
  required({ message: 'forms.error_required' }),
  minLength({ message: 'forms.error_min_length', count: 3 }),
  maxLength({ message: 'forms.error_max_length', count: 1000 }),
  pattern({ message: 'forms.login.user_name_error_pattern', pattern: /^[^\s]*$/ }),
)
const validateEmailRequired = composeValidators(
  required({ message: 'forms.error_required' }),
  pattern({ message: 'forms.client-account.error_email_pattern', pattern: EMAIL_PATTERN }),
)
const validateEmail = pattern({ message: 'forms.client-account.error_email_pattern', pattern: EMAIL_PATTERN })
const validatePassword = composeValidators(
  required({ message: 'forms.error_required' }),
  minLength({ message: 'forms.error_min_length', count: 7 }),
  matchesField({
    message: 'forms.error_matches_field',
    fieldName: 'reenterNewPassword',
    fields: 'passwords',
  }),
)
const validateReEnterPassword = composeValidators(
  required({ message: 'forms.error_required' }),
  minLength({ message: 'forms.error_min_length', count: 3 }),
  matchesField({
    message: 'forms.error_matches_field',
    fieldName: 'newPassword',
    fields: 'passwords',
  }),
)

const validateName = composeValidators(
  required({ message: 'forms.error_required' }),
  maxLength({ message: 'forms.error_max_length', count: 1000 }),
)

const validateGraduationYear = composeValidators(
  numRange({
    message: 'forms.client-account.graduation_year_error', min: 2001, max: 2100,
  }),
  required({ message: 'forms.error_required' }),
)

const validateStudentName = maxLength({ message: 'forms.error_max_length', count: 1000 })

const validateRoles = required({ message: 'forms.error_required' })
// const getWelcomeCourseOptions = (courses) => {
//   return courses
//     .filter((course) => course.isActive && WELCOME_COURSE_IDS.includes(course.id))
//     .map((course) => ({
//       value: course.id,
//       label: course.titles,
//       subLabel: course.descriptions,
//     }))
// }
const getRoleOption = (role) => ({
  key: `create-client-role-${role.type}`,
  text: (role.isInternal) ? `${role.name} (Internal Only)` : role.name,
  value: role.type,
  content: <Header size='small' content={(role.isInternal) ? `${role.name} (Internal Only)` : role.name} subheader={role.description} />,
})

const setFieldValue = (args, state) => {
  const [ name, data ] = args
  const field = state.fields[name]
  if (field) {
    field.change(data)
  }
}

export class CreateClientForm extends Component {
  static propTypes = {
    onSave: PropTypes.func.isRequired,
    color: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
    roles: PropTypes.array.isRequired,
    courses: PropTypes.array.isRequired,
    hideCourseSelection: PropTypes.bool,
    t: PropTypes.func,
  }

  static defaultProps = {
    onSave: console.log.bind(console, 'onSave'),
    color: 'teal',
    isLoading: false,
    hideCourseSelection: false,
    t: (key, opts = {}) => opts.defaultValue || key,
  }

  handleSaveForm = (data) => {
    const allCourses = data.courses || []
    this.props.onSave({
      ...data,
      courses: (this.props.hideCourseSelection) ? [] : allCourses,
      userName: ([ 'admin', 'supervisor', 'advisor' ].includes(data.role)) ? data.newEmail : data.newUserName,
      email: data.newEmail,
      password: data.newPassword,
      reenterPassword: data.reenterNewPassword,
      graduationYear: data.graduationYear,
    })
  }

  renderForm = ({
    handleSubmit,
    submitDisabled,
    form,
  }) => {
    const {
      color,
      isLoading,
      roles,
      courses,
      t,
      hideCourseSelection,
    } = this.props
    const otherAvailableCourses = courses.filter((course) => course.isActive && !WELCOME_COURSE_IDS.includes(course.id))
    const currentRole = get(form.getFieldState('role'), 'value')
    return (
      <Form className='create-clients-form' onSubmit={handleSubmit}>
        <Form.Group widths='equal'>
          <Field
            name='role'
            validate={validateRoles}
            validateFields={[]}
            render={({ input, meta: { error: { message, ...options } = {}, touched } }) => (
              <Form.Field disabled={isLoading}>
                <label data-public>{t('resource_types.role', { postProcess: 'titleCase' })} *</label>
                <Dropdown
                  className='create-client-input'
                  placeholder={t('forms.create-clients.roles_placeholder')}
                  error={!!message && touched}
                  fluid
                  search
                  selection
                  disabled={isLoading}
                  {...input}
                  options={roles.map(getRoleOption)}
                  onChange={dropdownOnChange(input)}>
                </Dropdown>
                {message && touched && <Label data-public color='red' basic pointing>{t(message, options)}</Label>}
              </Form.Field>
            )}
          />
          <ConditionalField
            data-public
            when='role'
            condition={(value) => value === 'adult'}
            name='isShared'
            validateFields={[]}
            render={({ input, meta: { error: { message, ...options } = {}, touched } }) => (
              <Form.Field style={{ marginTop: 32 }}>
                <Form.Checkbox
                  id='is-shared-input'
                  className='checkbox'
                  label={(
                    <label>Shared Account
                      <Popup
                        trigger={(<Icon className='base-teal' name='help circle' style={{
                          marginLeft: 5, fontSize: '1.1em', verticalAlign: 'top',
                        }}/>)}
                        position='right center'
                      >
                        <p>Shared parent/guardian accounts allow multiple people to share the login credentials to access BASE, and their module progress will not be saved on the account. Use this if you need an easy way to get parents/guardians access but do not need to see their progress.</p>
                      </Popup>
                    </label>
                  )}
                  {...input}
                  checked={!!input.value}
                  disabled={isLoading}
                  value='isShared'
                  onChange={checkboxOnChange(input)}
                />
              </Form.Field>
            )}
          />

        </Form.Group>
        <Field
          data-public
          name='newEmail'
          validate={([ 'admin', 'supervisor', 'advisor' ].includes(currentRole)) ? validateEmailRequired : validateEmail}
          format={lowerCaseFormatter}
          validateFields={[]}
          render={({ input, meta: { error: { message, ...options } = {}, touched } }) => (
            <Form.Field>
              <Form.Input
                className='create-client-input'
                placeholder={t('forms.email_label', { postProcess: 'titleCase' })}
                label={t('forms.email_label', { postProcess: 'titleCase' }) + (([ 'admin', 'supervisor', 'advisor' ].includes(currentRole)) ? ' *' : '')}
                error={!!message && touched}
                type='text'
                fluid
                disabled={isLoading}
                {...input}
                autoCorrect='off'
                autoCapitalize='none'
                spellCheck='false'
                onBlur={trimInputOnBlur(input)}
                onChange={(e, data) => {
                  ![ 'admin', 'supervisor', 'advisor' ].includes(currentRole) && form.mutators.setFieldValue('newUserName', data.value)
                  input.onChange(e, data)
                }}
              />
              {message && touched && <Label data-public color='red' basic pointing>{t(message, options)}</Label>}
            </Form.Field>
          )}
        />
        <ConditionalField
          data-public
          name='newUserName'
          when='role'
          condition={(value) => !!value && ![ 'admin', 'supervisor', 'advisor' ].includes(value)}
          validate={validateUserName}
          format={lowerCaseFormatter}
          validateFields={[]}
          render={({ input, meta: { error: { message, ...options } = {}, touched } }) => (
            <Form.Field>
              <Form.Input
                className='create-client-input'
                placeholder={t('forms.user_name_label', { postProcess: 'titleCase' })}
                label={(
                  <label>{t('forms.user_name_label', { postProcess: 'titleCase' }) + '*'}
                    <Popup
                      hoverable
                      trigger={(<Icon className='base-teal' name='help circle' style={{
                        marginLeft: 5, fontSize: '1.1em', verticalAlign: 'top',
                      }}/>)}
                      position='right center'
                    >
                      <p>We suggest using the email address as the user name to ensure uniqueness, but you are able to change it if needed.</p>
                    </Popup>
                  </label>
                )}
                error={!!message && touched}
                type='text'
                fluid
                disabled={isLoading}
                {...input}
                autoCorrect='off'
                autoCapitalize='none'
                spellCheck='false'
                onBlur={trimInputOnBlur(input)}
              />
              {message && touched && <Label data-public color='red' basic pointing>{t(message, options)}</Label>}
            </Form.Field>
          )}
        />

        <Form.Group widths='equal'>
          <Field
            data-public
            name='newPassword'
            validate={validatePassword}
            validateFields={[ 'newPassword', 'reenterNewPassword' ]}
            render={({ input, meta: { error: { message, ...options } = {}, touched } }) => (
              <Form.Field>
                <Form.Input
                  className='create-client-input'
                  placeholder={t('forms.password_label', { postProcess: 'titleCase' })}
                  label={t('forms.password_label', { postProcess: 'titleCase' }) + ' *'}
                  error={!!message && touched}
                  type='password'
                  fluid
                  disabled={isLoading}
                  {...input}
                  autoCorrect='off'
                  autoCapitalize='none'
                  spellCheck='false'
                />
                {message && touched && <Label data-public color='red' basic pointing>{t(message, options)}</Label>}
              </Form.Field>
            )}
          />
          <Field
            data-public
            name='reenterNewPassword'
            validate={validateReEnterPassword}
            validateFields={[ 'newPassword', 'reenterNewPassword' ]}
            render={({ input, meta: { error: { message, ...options } = {}, touched } }) => (
              <Form.Field>
                <Form.Input
                  className='create-client-input'
                  placeholder={`${t('re_enter')} ${t('forms.password_label', { postProcess: 'titleCase' })}`}
                  label={`${t('re_enter')} ${t('forms.password_label', { postProcess: 'titleCase' })} *`}
                  error={!!message && touched}
                  type='password'
                  fluid
                  disabled={isLoading}
                  {...input}
                  autoCorrect='off'
                  autoCapitalize='none'
                  spellCheck='false'
                />
                {message && touched && <Label data-public color='red' basic pointing>{t(message, options)}</Label>}
              </Form.Field>
            )}
          />
        </Form.Group>

        <Form.Group widths='equal'>
          <ConditionalField
            data-public
            when='role'
            condition={(value) => !!value && [ 'admin', 'supervisor', 'advisor' ].includes(value)}
            name='firstName'
            validate={validateName}
            validateFields={[]}
            render={({ input, meta: { error: { message, ...options } = {}, touched } }) => (
              <Form.Field>
                <Form.Input
                  className='create-client-input'
                  placeholder={'First Name'}
                  label={'First Name *'}
                  error={!!message && touched}
                  type='text'
                  fluid
                  disabled={isLoading}
                  {...input}
                  autoCorrect='off'
                  autoCapitalize='words'
                  spellCheck='false'
                />
                {message && touched && <Label data-public color='red' basic pointing>{t(message, options)}</Label>}
              </Form.Field>
            )}
          />
          <ConditionalField
            data-public
            when='role'
            condition={(value) => !!value && [ 'admin', 'supervisor', 'advisor' ].includes(value)}
            name='lastName'
            validate={validateName}
            validateFields={[]}
            render={({ input, meta: { error: { message, ...options } = {}, touched } }) => (
              <Form.Field>
                <Form.Input
                  className='create-client-input'
                  placeholder={'Last Name'}
                  label={'Last Name *'}
                  error={!!message && touched}
                  type='text'
                  fluid
                  disabled={isLoading}
                  {...input}
                  autoCorrect='off'
                  autoCapitalize='words'
                  spellCheck='false'
                />
                {message && touched && <Label data-public color='red' basic pointing>{t(message, options)}</Label>}
              </Form.Field>
            )}
          />
          <ConditionalField
            data-public
            when='role'
            condition={(value) => !!value && [ 'admin', 'supervisor', 'advisor' ].includes(value)}
            name='jobTitle'
            validateFields={[]}
            render={({ input, meta: { error: { message, ...options } = {}, touched } }) => (
              <Form.Field disabled={isLoading}>
                <label data-public>{t('resource_types.job_title', { postProcess: 'titleCase' })}</label>
                <Dropdown
                  className='create-client-input'
                  placeholder={t('forms.create-clients.roles_placeholder')}
                  error={!!message && touched}
                  fluid
                  search
                  selection
                  disabled={isLoading}
                  {...input}
                  options={JOB_TITLE_OPTIONS}
                  onChange={dropdownOnChange(input)}>
                </Dropdown>
                {message && touched && <Label data-public color='red' basic pointing>{t(message, options)}</Label>}
              </Form.Field>
            )}
          />
        </Form.Group>

        <Form.Group widths='equal'>
          <ConditionalField
            data-public
            when='role'
            condition={(value) => !!value && value === 'student'}
            name='firstName'
            validate={validateStudentName}
            validateFields={[]}
            render={({ input, meta: { error: { message, ...options } = {}, touched } }) => (
              <Form.Field>
                <Form.Input
                  className='create-client-input'
                  placeholder={'First Name'}
                  label={'First Name'}
                  error={!!message && touched}
                  type='text'
                  fluid
                  disabled={isLoading}
                  {...input}
                  autoCorrect='off'
                  autoCapitalize='words'
                  spellCheck='false'
                />
                {message && touched && <Label data-public color='red' basic pointing>{t(message, options)}</Label>}
              </Form.Field>
            )}
          />
          <ConditionalField
            data-public
            when='role'
            condition={(value) => !!value && value === 'student'}
            name='lastName'
            validate={validateStudentName}
            validateFields={[]}
            render={({ input, meta: { error: { message, ...options } = {}, touched } }) => (
              <Form.Field>
                <Form.Input
                  className='create-client-input'
                  placeholder={'Last Name'}
                  label={'Last Name'}
                  error={!!message && touched}
                  type='text'
                  fluid
                  disabled={isLoading}
                  {...input}
                  autoCorrect='off'
                  autoCapitalize='words'
                  spellCheck='false'
                />
                {message && touched && <Label data-public color='red' basic pointing>{t(message, options)}</Label>}
              </Form.Field>
            )}
          />
          <ConditionalField
            data-public
            when='role'
            condition={(value) => !!value && value === 'student'}
            name='graduationYear'
            validate={validateGraduationYear}
            render={({ input, meta: { error: { message, ...options } = {}, touched } }) => (
              <Form.Field>
                <Form.Input
                  className='create-client-input'
                  placeholder={'Graduation Year'}
                  label={(
                    <label>{'Graduation Year*'}
                      <Popup
                        hoverable
                        trigger={(<Icon className='base-teal' name='help circle' style={{
                          marginLeft: 5, fontSize: '1.1em', verticalAlign: 'top',
                        }}/>)}
                        position='right center'
                      >
                        <p>{'Student’s estimated'} <u><b>high school</b></u> {'graduation year. This will help Baseline provide the appropriate recommended modules.'}</p>
                      </Popup>
                    </label>
                  )}
                  error={!!message && touched}
                  type='number'
                  min={2001}
                  max={2100}
                  fluid
                  disabled={isLoading}
                  {...input}
                />
                {message && touched && <Label data-public color='red' basic pointing>{t(message, options)}</Label>}
              </Form.Field>
            )}
          />
        </Form.Group>

        {(!hideCourseSelection) && (
          <Field
            name='courses'
            render={({ input, meta: { error: { message, ...options } = {}, touched } }) => (
              <Form.Field disabled={isLoading}>
                <label data-public>{t('forms.create-client.courses_label')}</label>
                <Dropdown
                  className='create-client-input'
                  placeholder={t('forms.create-clients.courses_placeholder')}
                  error={!!message && touched}
                  clearable
                  fluid
                  search
                  multiple
                  selection
                  disabled={isLoading}
                  {...input}
                  options={otherAvailableCourses.map((course) => ({
                    key: `create-client-course-${course.id}`,
                    text: course.titles,
                    value: course.id,
                    content: <Header size='small' content={course.titles} subheader={course.descriptions} />,
                  }))}
                  onChange={dropdownOnChange(input)}>
                </Dropdown>
                {message && touched && <Label data-public color='red' basic pointing>{t(message, options)}</Label>}
              </Form.Field>
            )}
          />
        )}
        <br/>
        <Button
          type='submit'
          className='create-client-button'
          floated='right'
          loading={isLoading}
          color={color}
          disabled={submitDisabled}>
          {t('forms.create_button')}
        </Button>
        <br style={{ clear: 'both' }}/>
      </Form>
    )
  }

  render () {
    const {
      isLoading,
    } = this.props
    return (
      <BaseForm
        header=''
        onSubmit={this.handleSaveForm}
        disableWarning={true}
        isLoading={isLoading}
        initialValues={{
          courses: [],
          isShared: false,
          role: 'student',
        }}
        resetOnSubmit={true}
        render={this.renderForm}
        mutators={{ setFieldValue }}
      />
    )
  }
}

export default translate([ 'components' ])(CreateClientForm)
