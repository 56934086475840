import qs from 'querystring'

import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { connect } from 'react-redux'
import {
  Message, Icon,
} from 'semantic-ui-react'
import { translate } from 'react-i18next'
import localforage from 'localforage'
import to from 'await-to-js'
import pWaitFor from 'p-wait-for'

import {
  login,
  setToken,
  logout,
  setTokenError,
} from '../../../actions/authentication'
import isAuthenticated from '../../../helpers/is-authenticated'
import LoginForm from '../../forms/login/login'
import ResetPasswordVeiw from '../reset-password/reset-password'
import FullScreenLoadingOverlay from '../../common/full-screen-loading-overlay'

import './login.css'

const DB_NAME = (process.env.REACT_APP_NAME === 'BASEline') ? 'local.baseline.education' : 'local.base.education'
const localStore = localforage.createInstance({ name: DB_NAME })

export class LoginView extends React.Component {
  static propTypes = {
    authentication: PropTypes.object.isRequired,
    login: PropTypes.func.isRequired,
    setToken: PropTypes.func.isRequired,
    setTokenError: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    t: PropTypes.func,
    history: PropTypes.object.isRequired,
  }

  static path = '/login'

  render () {
    const {
      authentication,
      login,
      setToken,
      setTokenError,
      logout,
      t,
    } = this.props
    const queryParams = qs.parse(window.location.search.replace(/^\?/, ''))
    if (isAuthenticated(authentication)) {
      const hasSavedAuth = async () => {
        const [ err, savedState ] = await to(localStore.getItem('state'))
        if (err || !savedState) {
          return false
        }
        return isAuthenticated(savedState.authentication)
      }
      (async () => {
        const [ err ] = await to(pWaitFor(hasSavedAuth, { interval: 500, timeout: 5000 }))
        if (err) {
          logout()
          return
        }
        window.location.href = window.location.origin
      })()

      return (<FullScreenLoadingOverlay isActive={true}/>)
    } else if (queryParams.auth && !authentication.isLoading && !authentication.error) {
      const authStr = window.atob(queryParams.auth)
      try {
        const authObj = JSON.parse(authStr)
        setToken(authObj.access_token, authObj.refresh_token)
      } catch (ex) {
        console.error('Unable to parse query string auth param', ex, { authStr })
        ex.statusCode = 400
        setTokenError(ex)
        window.location.href = `${window.location.protocol}//${(process.env.REACT_APP_ENV) ? process.env.REACT_APP_ENV + '.' : ''}login.base.education?err=token`
      }
      return (<FullScreenLoadingOverlay isActive={true}/>)
    } else if (process.env.REACT_APP_ENV !== 'local' && !authentication.isLoading && !authentication.error) {
      window.location.href = `${window.location.protocol}//${(process.env.REACT_APP_ENV) ? process.env.REACT_APP_ENV + '.' : ''}login.base.education`
      return (<FullScreenLoadingOverlay isActive={true}/>)
    } else if (process.env.REACT_APP_ENV !== 'local' && !authentication.isLoading && !!authentication.error && authentication.error.message === 'session expired') {
      window.location.href = `${window.location.protocol}//${(process.env.REACT_APP_ENV) ? process.env.REACT_APP_ENV + '.' : ''}login.base.education?err=auth`
      return (<FullScreenLoadingOverlay isActive={true}/>)
    }

    const errorStatus = get(authentication, 'error.statusCode')
    const errorMessage = get(authentication, 'error.message')
    const showAuthExpiredError = !!authentication.error && authentication.error.message === 'session expired'
    return (
      <div className='login-page'>
        <LoginForm
          onLogin={login}
          isLoading={authentication.isLoading}
          showInvalidCredentialsError={errorStatus === 400}
          showNotAllowedError={errorStatus === 403 && errorMessage.includes('currently inactive')}
          showRestrictedError={errorStatus === 403 && !errorMessage.includes('currently inactive')}
          showStudentsNotAllowedError={errorStatus === 418}
          showNotConnectedError={errorStatus === 0}
          showAuthExpiredError={showAuthExpiredError || errorStatus === 401}
        />
        <Message data-public icon>
          <Icon name='info circle' />
          <Message.Content>
            <Message.Header>
              {t('views.login.forgot_password')}
            </Message.Header>
            <p><a href={`#${ResetPasswordVeiw.path}`}>{t('views.login.click_here')}</a></p>
          </Message.Content>
        </Message>
        <br/>
        <Message data-public icon>
          <Icon name='help circle' />
          <Message.Content>
            <Message.Header>
              {t('views.login.more_help_header')}
            </Message.Header>
            <p>{t('views.login.more_help')} <a href='mailto:support@7mindsetsportal.com'>support@7mindsetsportal.com</a></p>
          </Message.Content>
        </Message>
        <br/>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const {
    authentication,
  } = state
  return {
    authentication,
  }
}
const mapDispatchToProps = {
  login,
  setToken,
  setTokenError,
  logout,
}
const LoginViewContainer = connect(mapStateToProps, mapDispatchToProps)(LoginView)

export default translate([ 'components' ])(LoginViewContainer)
