import gql from 'graphql-tag'

const GET_MODULE_COMPLETION_RATE_BY_ORG_ID = gql`
query getModuleCompletionRateByOrgId($orgId: String!, $assessmentId: String!, $schoolYear: Int!) {
  getModuleCompletionRateByOrgId(
    orgId: $orgId
    assessmentId: $assessmentId
    schoolYear: $schoolYear
  )
}
`

export default GET_MODULE_COMPLETION_RATE_BY_ORG_ID
